import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham";
import styled from "styled-components";
import { Radio } from "@lib/components";
import PreviewTransfer from "./previewTransfer";
import { api } from "../../api";
import { RotateLoader } from "@lib/components";

interface Props {
  toggleMode: any;
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 42%;
	margin: 0 auto;
`;

const TransferWrapper = styled.div`
	display: inline-flex;
	margin-top: 50px;
`;

const TableWrapper = styled.div`
	margin-top: 20px;
	width: auto;
`;

const RestaurantTitle = styled.span`
	font-weight: 600;
	font-size: 26px;
	display: block;
`;

const TransferTitle = styled.div`
	font-weight: 600;
	font-size: 26px;
`;

const RadioButton = styled.span`
	margin-left: 30px;
	display: flex;
`;

const PrevewButton = styled.button`
	min-width: 60px;
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	margin-left: auto;
	padding: 5px 10px;
    font-size: 14px;
`;

const BackButton = styled.button`
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	min-width: 50px;
    padding: 5px 10px;
    font-size: 14px;
`;

const ButtonWrapper = styled.div`
	margin-top: 70px;
	display: flex;
`
class TransferRestaurant extends React.Component<Props, any> {
	restaurantGridApi: any;
	transferGridApi: any;
	restaurantGridColumnApi: any;
	transferGridColumnApi: any;
	columnTransferDefs: any = [];
	restaurantData = [];
	dataComputed: any = [];
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			gridTransferLoading: false,
			step: 1,
			columnDefs: [],
			restaurantData: [],
			resellerData: [],
			organizationData: [],
			transferMode: "",
		};
	}

	loadSeletedRows = (selectedRows: any, girdApi: any) => {
		if(!selectedRows) selectedRows = [];
		girdApi.forEachNode((node: any) => {
			const selectNode = selectedRows.some((row: any) => {
				return row._id === node.data._id;
			});

			if (selectNode) {
				node.setSelected(true, false);
			}
		});
	};

	onRestaurantGridReady = (params: any) => {
		this.restaurantGridApi = params.api;
		this.restaurantGridColumnApi = params.columnApi;
		let selectedRows = JSON.parse(localStorage.getItem("selectedRs") as string);
		this.loadSeletedRows(selectedRows, this.restaurantGridApi);
	};

	onTransferGridReady = (params: any) => {
		this.transferGridApi = params.api;
		this.transferGridColumnApi = params.columnApi;
		let selectedRows = JSON.parse(localStorage.getItem("transferData") as string);
		this.loadSeletedRows(selectedRows, this.transferGridApi);
	};

	onRestaurantRowChanged = () => {
		this.dataComputed = this.restaurantGridApi.getSelectedRows();
		const selectedRs = this.restaurantGridApi.getSelectedRows();
		localStorage.setItem("selectedRs", JSON.stringify(selectedRs));
	};

	onTransferRowChanged = () => {
		const transferData = this.transferGridApi.getSelectedRows();
		localStorage.setItem("transferData", JSON.stringify(transferData));
	};

	defaultcolumnDefs: any = [
		{
			headerName: "Name",
			field: "name",
			sortable: true,
			filter: "agTextColumnFilter",
			checkboxSelection: true,
		},
		{
			headerName: "Subdomain",
			field: "subdomain",
			sortable: true,
			filter: "agTextColumnFilter",
		},
		{
			headerName: "Reseller",
			field: "reseller_id",
			sortable: true,
			filter: "agTextColumnFilter",
		},
		{
			headerName: "User",
			field: "user",
			sortable: true,
			filter: "agTextColumnFilter",
		},
	];

	defaultColDefProperty = {
		flex: 1,
		floatingFilter: true,
	};

	setPage = (page: number) => {
		this.setState({ step: page });
	};

	onClickPreview = () => {
		this.setState({ step: 2 });
	};

	onResellerSelected = async () => {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "Reseller",
				field: "_id",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
		];
		const resellerData = await api.getResellers();
		localStorage.removeItem('transferData');
		this.setState({
			resellerData: resellerData.resellers,
			transferMode: "to_reseller",
			gridTransferLoading: false
		});
	};

	onOrganizationSelected = async () => {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "User",
				field: "email",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
			{
				headerName: "Reseller",
				field: "reseller_id",
				sortable: true,
				filter: "agTextColumnFilter",
			},
		];
		const organizationData = await api.getOrganizations();
		localStorage.removeItem('transferData');
		this.setState({
			organizationData: organizationData.organizations,
			transferMode: "to_organization",
			gridTransferLoading: false,
		});
	};

	async componentDidMount() {
		this.setState({ loading: true });
		const restaurants = await api.getRestaurants();
		await this.onResellerSelected();
		this.setState({ restaurantData: restaurants.restaurants, loading: false });
	}

	onTransferSuccess = async () => {
		this.setState({ loading: true });
		const restaurants = await api.getRestaurants();
		await this.onResellerSelected();
		this.setState({ restaurantData: restaurants.restaurants, loading: false });
	}

	computedGridHeight = () => {
		let tableRestaurantHeight = "";
		let tableOrgHeight = "";
		let domLayoutReseller = "autoHeight";
		let domLayoutOrg = "autoHeight";

		if (this.state.resellerData.length > 5) {
			tableRestaurantHeight = "200px";
			domLayoutReseller = "normal";
		} else if (this.state.organizationData.length > 5) {
			tableOrgHeight = "200px";
			domLayoutOrg = "normal";
		}

		return {
			tableRestaurantHeight,
			tableOrgHeight,
			domLayoutReseller,
			domLayoutOrg,
		};
	};

	toggleMode = (newMode: string) => {
		this.props.toggleMode(newMode);
	};

	render() {
		const {tableRestaurantHeight, tableOrgHeight, domLayoutReseller, domLayoutOrg} = this.computedGridHeight();
		return (
			<>
				{this.state.loading && <RotateLoader size={3} />}
				{this.state.step === 1 && !this.state.loading && (
					<>
						<MainWrapper>
							<RestaurantTitle>
								Restaurant to Transfer
							</RestaurantTitle>
							<p>Select one more</p>
							<TableWrapper>
								<div
									className="ag-theme-balham"
									style={{ width: "100%", height: "250px" }}>
									<AgGridReact
										columnDefs={this.defaultcolumnDefs}
										onSelectionChanged={this.onRestaurantRowChanged}
										rowData={this.state.restaurantData}
										defaultColDef={this.defaultColDefProperty}
										onGridReady={this.onRestaurantGridReady}
										rowSelection="multiple"
									/>
								</div>
							</TableWrapper>

							<TransferWrapper>
								<TransferTitle>Transfer To</TransferTitle>
								<RadioButton>
									<Radio
										id={"reseller"}
										name="reseller"
										checked={this.state.transferMode === "to_reseller"}
										title={"Reseller"}
										value={"reseller"}
										onChange={() => this.onResellerSelected()}
									/>
								</RadioButton>
								<RadioButton>
									<Radio
										id={"organization"}
										name="organization"
										checked={this.state.transferMode === "to_organization"}
										title={"Organization"}
										value={"organization"}
										onChange={() => this.onOrganizationSelected()}
									/>
								</RadioButton>
							</TransferWrapper>
							<p>Select one Reseller</p>
							{this.state.gridTransferLoading && (
								<RotateLoader size={3} />
							)}
							{this.state.transferMode === "to_reseller" &&
								!this.state.gridTransferLoading && (
									<TableWrapper>
										<div
											className="ag-theme-balham"
											style={{width: "65%",height: tableRestaurantHeight}}>
											<AgGridReact
												columnDefs={this.columnTransferDefs}
												onSelectionChanged={this.onTransferRowChanged}
												rowData={this.state.resellerData}
												defaultColDef={this.defaultColDefProperty}
												onGridReady={this.onTransferGridReady}
												domLayout={domLayoutReseller}
											/>
										</div>
									</TableWrapper>
								)}
							{this.state.transferMode === "to_organization" &&
								!this.state.gridTransferLoading && (
									<TableWrapper>
										<div
											className="ag-theme-balham"
											style={{width: "85%",height: tableOrgHeight}}>
											<AgGridReact
												columnDefs={this.columnTransferDefs}
												onSelectionChanged={this.onTransferRowChanged}
												rowData={this.state.organizationData}
												defaultColDef={this.defaultColDefProperty}
												onGridReady={this.onTransferGridReady}
												domLayout={domLayoutOrg}
											/>
										</div>
									</TableWrapper>
								)}
							<ButtonWrapper>
								<BackButton
									onClick={() => {this.props.toggleMode("N")}}>
									Back
								</BackButton>
								<PrevewButton onClick={this.onClickPreview}>
									Preview
								</PrevewButton>
							</ButtonWrapper>
						</MainWrapper>
					</>
				)}

				{this.state.step === 2 && !this.state.loading && (
					<PreviewTransfer
						onTransferSuccess={this.onTransferSuccess}
						processedData={this.dataComputed}
						setPage={this.setPage}
						transferType={this.state.transferMode}
					/>
				)}
			</>
		);
	}
}

export default TransferRestaurant;
